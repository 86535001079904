// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataAgency: null,
    respDataDepartment: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_AGENCY(state, payload) {
      state.respDataAgency = payload
    },
    GET_DEPARTMENT(state, payload) {
      state.respDataDepartment = payload
    },
    ADD(state, payload) {
      if (state.respData != null) {
        state.respData.data.push(payload)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max + 1
      } else {
        state.respData.data = [payload]
        state.respData.max = 1
      }
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/questionReview/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAgency({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/questionReview/getAgency?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_AGENCY', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getDepartment({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/questionReview/getDepartment?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_DEPARTMENT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/questionReview/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    addQuestion({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/questionReview/addQuestion', item)
          .then(response => {
            // commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateQuestion({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/questionReview/updateQuestion', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    view({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/questionReview/view?id=${item.id}`)
          .then(response => {
            // commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/application/hero/questionReview/delete?id=${item}`)
          .then(response => {
            commit('REMOVE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
